<template>
    <div v-if="$isFHAdmin == false">
        <h1>Sorry, you don't have access to this page.</h1>
    </div>
    <div class="row" v-if="$isFHAdmin == true">
        <div class="col-md-12" style="text-align: center">
            <h4 class="text-center">Current Users</h4> 
            <table class="table table-striped" style="text-align: left">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Access Level</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="user in Users" :key="user.key">
                        <td>{{ user.username }}</td>
                        <td>
                            <select class="form-control" v-model="user.approved" @click="this.Approve(user, user.approved)" style="border: 2px solid gray">
                                <option :value="'admin'" @click="ApproveAdmin(user)">Admin Access</option>
                                <option :value="'true'" @click="Approve(user)">Full Access</option>
                                <option :value="'view'" @click="ApproveView(user)">View Only</option>
                                <option :value="'false'" @click="Remove(user)" style="background-color: red; color: white; hover: red">No Access</option>
                            </select>
                         </td>
                    </tr>
                </tbody>
            </table>
            
            
            <h4 class="text-center">Invited Users</h4> 
            <button @click="$router.push('/adminadd')" class="btn btn-primary">Invite New User</button>
            <table class="table" style="text-align: left">
                <thead>
                    <tr>
                        <th>Email</th>
                        <th>Access Level</th>
                    </tr>
                </thead>
                <tbody v-for="user in InvitedUsers" :key="user.key">
                    <tr style="border-top: 2px solid black">
                        <td>{{ user.email }}</td>
                        <td>
                            <select class="form-control" v-model="user.approved" @click="this.Approve(user, user.approved)" style="border: 2px solid gray">
                                <option :value="'admin'" @click="ApproveAdmin(user)">Admin Access</option>
                                <option :value="'true'" @click="Approve(user)">Full Access</option>
                                <option :value="'view'" @click="ApproveView(user)">View Only</option>
                                <option :value="'false'" @click="Remove(user)" style="background-color: red; color: white; hover: red">No Access</option>
                            </select>
                         </td>
                    </tr>
                    <tr style="border-bottom: 2px solid black">
                        <td><button style="width: 90%" @click="copyURL('https://funeralservicesoftware.com/signup/'+user.invitelink)" class="btn btn-primary">Copy Invite Link</button></td>
                        <td><button style="width: 90%" @click="Uninvite(user.key)" class="btn btn-danger">Remove Invite</button></td>
                    </tr>
                </tbody>
            </table>
            <div v-if="currentTenant == 'admin'">
                <h3 class="text-center"><button @click="$router.push('/superadminadd')" class="btn btn-primary">Add New FH</button></h3>
            </div>
        </div>
    </div>
</template>

<script>
    import { db } from '../firebaseDb';
    import Auth from '@aws-amplify/auth';
    export default {
        data() {
            return {
                Users: [],
                InvitedUsers: [],
                currentTenant: "",
                service: {},
                serviceid: "",
                newCem: {},
                myusername: "",
                accessvalue: "true",
                selected: "",
            }
        },
        created() {
            Auth.currentAuthenticatedUser({
                
            }).then(user => { 
                this.currentTenant = this.$tenant;
                console.log(this.$isFHAdmin);
                this.myusername = user.username;
            })
            this.updateservices();
        },
        methods: {
            updateservices() {
                db.collection('pendingusers').orderBy('username').onSnapshot((snapshotChange) => {
                this.Users = [];
                this.InvitedUsers = [];
                snapshotChange.forEach((doc) => {
                    if(this.currentTenant == doc.data().fh && String(doc.data().username).toLowerCase() !== this.myusername.toLowerCase() && doc.data().username !== "") {
                    this.Users.push({
                        key: doc.id,
                        username: doc.data().username,
                        fh: doc.data().fh,
                        approved: doc.data().approved,
                    })
                    }
                    if(this.currentTenant != 'admin') {
                    if(this.currentTenant == doc.data().fh && String(doc.data().username).toLowerCase() !== this.myusername.toLowerCase() && doc.data().username == "") {
                    this.InvitedUsers.push({
                        key: doc.id,
                        email: doc.data().email,
                        fh: doc.data().fh,
                        approved: doc.data().approved,
                        invitelink: doc.data().invitelink,
                    })
                    }
                    }
                    else if(this.currentTenant == 'admin') {
                        if(String(doc.data().username).toLowerCase() !== this.myusername.toLowerCase() && doc.data().username == "") {
                        this.InvitedUsers.push({
                        key: doc.id,
                        email: doc.data().email,
                        fh: doc.data().fh,
                        approved: doc.data().approved,
                        invitelink: doc.data().invitelink,
                    })
                        }
                    }
                });
            })
            },
            copyURL(text) {
                window.navigator.clipboard.writeText(text)
            },
            async Approve(user, approved){
                user.approved = approved;
                db.collection('pendingusers').doc(user.key)
                        .update(user).then(() => {
                            console.log("Updated!");
                        }).catch((error) => {
                            console.log(error);
                        });
            },
            async ApproveView(user){
                user.approved = 'view';
                db.collection('pendingusers').doc(user.key)
                        .update(user).then(() => {
                            console.log("Updated!");
                        }).catch((error) => {
                            console.log(error);
                        });
                  
            },
            async Remove(user) {
                user.approved = 'false';
                db.collection('pendingusers').doc(user.key)
                        .update(user).then(() => {
                            console.log("Updated!");
                        }).catch((error) => {
                            console.log(error);
                        });
            },
            async Uninvite(id) {
                if (window.confirm("Do you really want to uninvite this user?")) {
                db.collection("pendingusers").doc(id).delete().then(() => {
                    console.log("Invite deleted!");
                })
                .catch((error) => {
                    console.error(error);
                })
              }
            }
        }
    }
</script>

<style>
    .false {
        background-color: red;
    }
    .btn-primary {
        margin-right: 12px;
    }
</style>